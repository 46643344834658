<template>
    <div class="card">
        <div class="card_head">
            <div class="title" v-if="!is_title">
                {{ title }}
            </div>
            <slot name="title"></slot>
        </div>
        <div class="card_body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "hotkeey_card",
    props: ["title"],
    data() {
        return {
            is_title: false,
        };
    },
    mounted() {
        if (this.$slots.title) this.is_title = true;
    },

    methods: {},
};
</script>

<style lang="less" scoped>
.card {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 16px;
}
.title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 16px;
    color: #000;
}
</style>