<template>
    <div>
        <div class="hd">
            <div class="shortcut_box">
                <shortcut_box />
            </div>
            <div class="account_box">
                <account1 />
            </div>
        </div>
        <CARD title="数据面板">
            <template #body>
                <div class="data_box">
                    <template v-for="(item, index) in userCount">
                        <div
                            :key="index"
                            v-if="!item.hidden"
                            class="data_item"
                            :style="{ background: item.backcolor }"
                        >
                            <div class="data_title">{{ item.title }}</div>
                            <div class="data_num">
                                {{ item.count }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </CARD>
        <CARD title="最近创作工程">
            <template #title="">
                <div class="title">
                    <div>最近创作工程</div>
                    <router-link to="/home/work/project_list"
                        >查看更多</router-link
                    >
                </div>
            </template>
            <template #body>
                <PROJECTS :autoLoad="false" ref="projects" />
            </template>
        </CARD>
    </div>
</template>

<script>
import shortcut_box from "@/components/v3/shortcut/shortcut.vue";
import { getProje, delProje } from "@/api/engi.js";
import account1 from "@/components/v3/account/account1.vue";
import CARD from "@/components/v3/card.vue";
import PROJECTS from "@/components/v3/project_list";
import { getFanportrait, getAuthorizedUsersCount } from "@/api/index";
export default {
    name: "shortcut",
    components: {
        account1,
        shortcut_box,
        CARD,
        PROJECTS,
    },
    data() {
        return {
            list: [],
            userCount: {
                shareCount: {
                    // 分享数
                    count: 0,
                    title: "分享量",
                    backcolor:
                        "linear-gradient(90deg, #20D3FD 2%, #3D7AFF 99%)",
                },
                diggCount: {
                    //点赞
                    title: "点赞量",
                    count: 0,
                    backcolor:
                        "linear-gradient(45deg, #FF9A93 0%, #FF6F8E 100%)",
                },
                playCount: {
                    // 播放量
                    title: "播放量",
                    count: 0,
                    backcolor:
                        "linear-gradient(48deg, #08AFE9 0%, #2AF499 100%)",
                },
                commentCount: {
                    //评论数
                    title: "评论数",
                    count: 0,
                    backcolor:
                        "linear-gradient(224deg, #FD83EC 0%, #6F7CFF 100%)",
                },
                downloadCount: {
                    // 下载书
                    title: "下载量",
                    count: 0,
                    backcolor: "",
                    hidden: true,
                },
            },
        };
    },

    mounted() {
        getAuthorizedUsersCount().then((res) => {
            if (res.code) {
                for (const key in res.data) {
                    this.userCount[key] &&
                        (this.userCount[key].count = res.data[key]);
                }
            }
        });
        let getProjectWhere = {
            size: this.$refs.projects.col * 2,
            current: 1,
            projectType: "BlendClip",
        };
        getProje(getProjectWhere).then((res) => {
            this.list = res.data.records;
            // console.log(res);
        });
    },

    methods: {},
};
</script>

<style lang="less" scoped>
.hd {
    display: flex;
    .shortcut_box {
        flex: 1;
    }
    .account_box {
        margin-left: 16px;
        width: 260px;
    }
}
.data_box {
    display: flex;
    .data_item {
        padding: 20px;
        flex: 1;
        min-width: 280px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        transition: all 0.2s linear;
        &:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
            transform: translateY(-2px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            &::before {
                left: 54%;
                top: 100px;
            }
            &::after {
                right: 54%;
                bottom: 100px;
            }
        }
        &::before {
            content: "";
            display: block;
            width: 80px;
            height: 80px;
            position: absolute;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.4);
            background-color: rgba(255, 255, 255, 0.3);
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-100%, -100%);
            transition: all 0.5s ease-in-out;
        }
        &::after {
            content: "";
            display: block;
            width: 80px;
            height: 80px;
            position: absolute;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.4);
            background-color: rgba(255, 255, 255, 0.3);
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(100%, 100%);
            transition: all 0.5s ease-in-out;
        }
        .data_title {
            color: #fff;
            font-size: 16px;
        }
        .data_num {
            font-size: 24px;
            color: #fff;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        & + .data_item {
            margin-left: 16px;
        }
    }
}
</style>