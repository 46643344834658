<template>
    <div class="shortcut_content">
        <div class="title">
            AIGC <span style="font-size: 12px; color: aqua">(暂未开放)</span>
        </div>
        <div class="textarea">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4 }"
                resize="none"
                placeholder="请输入文本或拖拽上传视频/图片（可快速生成脚本、解析视频、生成产品图等）"
                v-model="textarea"
            >
            </el-input>
        </div>
    </div>
</template>

<script>
export default {
    name: "Shortcut",

    data() {
        return {
            textarea: "",
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.shortcut_content {
    height: 200px;
    background: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/shortcut_box_bg.jpg)
        no-repeat center center;
    background-size: cover;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 28px 40px;
    .title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
    }
    .textarea {
        max-width: 640px;
        /deep/ .el-textarea__inner {
            background-color: rgba(255, 255, 255, 0.3);
            font-family: source_regular;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
    }
}
</style>